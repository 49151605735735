export const allfilterArr = [
	[
		{
			label: '客户',
			type: 'input',
			attr: 'search',
			placeholder: '请输入客户姓名/手机号',
			column: 5,
		},
		{
			label: '客户来源',
			type: 'select',
			attr: 'source',
			placeholder: '请选择',
			column: 5,
		},
		{
			label: '客户星级',
			type: 'select',
			attr: 'starStatus',
			placeholder: '请选择',
			column: 4,
			option: [],
		},
		{
			label: '入库时间',
			type: 'dateRange',
			attr: 'followDate',
			valueFormat:"YYYY-MM-DD",
			placeholder: '请选择',
			column: 7,
		},
		{ type: 'button', column: 3 },
	],
]
export const tableColumn = [
	{
		label: '用户名',
		prop: 'name',
		width: '120px',
		fixed: true,
		isCustomize: true,
		tableType: 'nameNoPointer',
	},
	{
		label: 'ID',
		prop: 'id',
		width: '80px',
	},
	{
		label: '年龄',
		prop: 'age',
		width: '80px',
	},
	{
		label: '性别',
		prop: 'sex',
		width: '80px',
		isCustomize: true,
		tableType: 'sexNumber',
	},
	{
		label: '额度',
		prop: 'loanLimit',
		width: '80px',
	},
	{
		label: '跟进人',
		prop: 'username',
		width: '120px',
	},
	{
		label: '星级',
		prop: 'starStatus',
		width: '80px',
		isCustomize:true,
		tableType: "starStatus"
	},
	{
		label: '跟进状态',
		prop: 'status',
		width: '100px',
		isCustomize: true,
		tableType: 'followUpStatus'
	},
	{
		label: '客户来源',
		prop: 'channelName',
		width: '100px'
	},
	{
		label: '跟进情况备注(点击查看更多)',
		prop: 'followRecords',
		width: '250px',
		isCustomize: true,
		tableType: 'followRecords',
	},
	{
		label: '客户条件(备注)',
		prop: 'remark',
		width: '250px',
	},
	{
		label: '客户属性',
		prop: 'clientProperty',
		width: '250px',
	},
	{
		label: '跟进次数',
		prop: 'followNum',
		width: '100px',
	},
	{
		label: '分配时间',
		prop: 'distributionTime',
		width: '200px',
	},
	{
		label: '再分配时间',
		prop: 'redistributionTime',
		width: '200px',
	},
	{
		label: '最后跟进时间',
		prop: 'lastFollowTime',
		width: '200px',
	},
	{
		label: '申请时间',
		prop: 'createTime',
		width: '200px',
	},
]