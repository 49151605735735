<template>
  <FilterForm
      :formData="filterData"
      :filters="filters"
      @closeUp="closeUp"
      @unflod="unflod"
      @submit="handleSubmit"
      @reset="handleReset"
      @changeDept="changeDept"
  />
  <NewCustomerTable
      :tableColumn="tableColumn"
      :tableData="tableData"
      :selection="true"
      @selection="handleSelectionChange"
      @nameDrawer="handleNameDrawer"
      @followRecords="followRecords"
  />
  <NewCustomerPagination
      :total="total"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
  />
  <DialogFollowRecords
      :isShowDialog="isShowFollow"
      :width="500"
      @close="handleFollowClose"
      :list="followList"
  />
  <CustomerDetail
      width="1200px"
      :isShowDialog="isShowDrawer"
      :isShowSaveBtn="true"
      :isHiddenOperation="true"
      :isHiddenTX="true"
      @close="handleDrawerClose"
      direction="rtl"
      :userId="drawerId"
  />
  <DistributionDialog
      title="�����"
      :isShowDialog="isShowDialog"
      :forms="forms"
      :formData="formData"
      :width="500"
      @submit="handleDistributionSubmit"
      @close="handleClose"
      @prev="handlePrevCustomer"
      @next="handleNextCustomer"
  />
</template>

<script>
import FilterForm from "@/components/filter";
import NewCustomerTable from "@/components/table";
import NewCustomerPagination from "@/components/pagination";
import { computed, onMounted, reactive, ref, watch } from "vue";
import {  allfilterArr, tableColumn } from "./index.js";
import { useStore } from "vuex";
import { getDictionaries } from "@/api/method/common";
import DialogFollowRecords from "@/components/dialog-followRecords";
import { reset } from "@/until";
import CustomerDetail from "@/components/drawer-form/index.vue";
import DistributionDialog from "@/components/dialog-form";
import { getAllUsers } from "@/api/method/system";
import { distribute } from "@/api/method/customer";
import { getChannelList } from "@/api/method/channel";
import { ElButton, ElMessage } from "element-plus";

export default {
  name: "NewCustomer",
  props: ["options", "departments"],
  components: {
    FilterForm,
    NewCustomerTable,
    NewCustomerPagination,
    DialogFollowRecords,
    CustomerDetail,
    DistributionDialog,
    [ElButton.name]: ElButton,
  },

  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      search: null,
      starStatus: null,
      status: null,
      followDate: null,
      source: null,
      remark: null,
    });

    const filters = ref(allfilterArr);

    const unflod = () => {
      filters.value = allfilterArr;
    };

    onMounted(() => {
      getList();
      dictionaries();
      loadChannelList();
    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(
        () => (state.fullCustomerList && state.fullCustomerList.records) || []
    );
    const total = computed(
        () => state.fullCustomerList && state.fullCustomerList.total
    );
    const getList = () => {
      customerIds.value = [];
      const { followDate, ...params } = filterData;
      const createStartDate = followDate && followDate[0];
      const createEndDate = followDate && followDate[1];
      dispatch("fullCustomerList", {
        ...pagination,
        createStartDate,
        createEndDate,
        ...params,
      });
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const filterOption = ref(null);
    watch(
        () => filters.value,
    );

    const dictionaries = async () => {
      const res = await getDictionaries();
      filterOption.value = res.data;
      filters.value[0][2].option = filterOption.value.starList.map(
          (item) => ({
            label: item.label,
            val: item.vlaue,
          })
      );
    };

    const channelList = ref([]);
    const loadChannelList = async () => {
      const res = await getChannelList();

      channelList.value = res.data.map((item) => ({
        label: item.name,
        val: item.id,
      }));

      filters.value[0][1].option = channelList.value;
    }
    const closeUp = () => {
      filters.value = allfilterArr;
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };
    const customerIds = ref([]);
    const handleSelectionChange = (arr) => {
      customerIds.value = arr.map((item) => item.id);
    };
    const isShowFollow = ref(false);
    const followList = ref([]);

    const followRecords = (row) => {
      isShowFollow.value = true;
      followList.value = row.followRecords;
    };
    const handleFollowClose = () => (isShowFollow.value = false);

    const isShowDrawer = ref(false);
    const handleDrawerClose = () => (isShowDrawer.value = false);
    const drawerId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDrawer.value = true;
      drawerId.value = row.id;
    };
    /**�ͻ�����-��һ������һ�� */
    const handleNextCustomer = () => {
      const index =
          state.newCustomerList.records.findIndex(
              (item) => item.id == drawerId.value
          ) + 1;
      if (index == state.newCustomerList.records.length) {
        ElMessage.error("�Ѿ������һ���ͻ��ˣ�");
        return;
      }
      drawerId.value = state.newCustomerList.records[index].id;
    };
    const handlePrevCustomer = () => {
      const index =
          state.newCustomerList.records.findIndex(
              (item) => item.id == drawerId.value
          ) - 1;
      if (index < 0) {
        ElMessage.error("�Ѿ��ǵ�һ���ͻ��ˣ�");
        return;
      }
      drawerId.value = state.newCustomerList.records[index].id;
    };
    /**��������� */
    const handleDistribution = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("�빴ѡ�ͻ������");
        return;
      }
      isShowDialog.value = true;
      const res = await getAllUsers();

      forms[0][0].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };
    const isShowDialog = ref(false);
    const handleClose = () => (isShowDialog.value = false);
    const forms = reactive([
      [
        {
          label: "�����",
          type: "select",
          multiple: true,
          attr: "userId",
          placeholder: "��ѡ��������Ա",
          column: 24,
          labelWidth: "120px",
          option: [],
        },
      ],
    ]);
    const formData = reactive({
      userId: null,
    });
    const handleDistributionSubmit = async () => {
      const res = await distribute({
        userId: formData.userId,
        customerIds: customerIds.value,
      });

      if (res.code == 200) {
        ElMessage.success("����ɹ���");
        getList();
        handleClose();
      } else {
        ElMessage.error(res.msg);
      }
    };

    return {
      filters,
      filterData,
      unflod,
      closeUp,
      handleSubmit,
      handleReset,
      tableData,
      tableColumn,
      total,
      handleSelectionChange,
      followRecords,
      isShowFollow,
      followList,
      handleFollowClose,
      isShowDrawer,
      handleDrawerClose,
      handleNameDrawer,
      drawerId,
      handleDistribution,
      isShowDialog,
      handleClose,
      handleDistributionSubmit,
      forms,
      formData,
      currentChange,
      sizeChange,
      handlePrevCustomer,
      handleNextCustomer
    };
  },
};
</script>

<style>
</style>